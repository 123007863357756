import PasswordModal from "components/Modal/UserProfile/PasswordModal";
import { useState, useRef, useEffect } from "react";
import _userDefaultProfileImg from "assets/images/_user_default_profile.png";
import {
  useGetUserProfileByTokenQuery,
  useUpdateAccountPrivateMutation,
  useDeleteUserOtpMutation,
  useDeleteUserMutation,
} from "store/slicer/userProfile";
import spinner from "assets/images/spinner_.gif";
import { useUpdateUserInfoDataByTokenMutation } from "store/slicer/userProfile";
import { IsStringValid } from "utils";
import { useRemoveUserProfilePhotoByTokenMutation } from "store/slicer/userProfile";
import Loader from "components/Loader/Loader";
import { useUpdateUsernameWithGenderAndDobMutation } from "store/slicer/userProfile";
import ProfilePhotoModal from "components/Modal/UserProfile/ProfilePhotoModal";
import { useUpdateUserProfilePhotoByTokenMutation } from "store/slicer/userProfile";
import { useNavigate, useLocation, NavLink, Link } from "react-router-dom";

import dateFormat from "dateformat";
import { Calendar as HyperCalendar } from "react-date-range";
import "assets/css/hyperxcalendarstyles.css"; // main style file
import "assets/css/hyperxcalendartheme.css"; // theme css file
import { isValidUsername } from "utils";
import ConfirmDeletion from "components/popup/account-deletion/ConfirmDeletion";
import Deleted from "components/popup/account-deletion/Deleted";
import DeletionError from "components/popup/account-deletion/DeletionError";
/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-MyProfile-Component
 *@developer Aditya P
 */

const dateConversionTo = (dateString, key) => {
  // Create a new Date object from the given string
  const date =
    key === "DOB" ? new Date(dateString) : new Date(dateString * 1000);

  // Get the month, day, and year from the Date object
  const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date as MM/DD/YYYY
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

const MyProfile = () => {
  // getting user info data from an API
  const { data, error, isLoading, status, refetch } =
    useGetUserProfileByTokenQuery();
  const [updateUserInfo] = useUpdateUserInfoDataByTokenMutation();
  const [removeProfilePic] = useRemoveUserProfilePhotoByTokenMutation();
  const [updateUsernameandDobGenderQuery] =
    useUpdateUsernameWithGenderAndDobMutation();
  const [UpdateProfilepic] = useUpdateUserProfilePhotoByTokenMutation();
  const navigate = useNavigate();
  const [sendDeleteUserOtp, sendDeleteUserOtpInfo] = useDeleteUserOtpMutation();
  const [reSendDeleteUserOtp, reSendDeleteUserOtpInfo] =
    useDeleteUserOtpMutation();
  const [deleteUser, deleteUserInfo] = useDeleteUserMutation();
  const focusRef = useRef(null);
  const [isformEditable, setFormEditable] = useState(false);
  const [isModalStatus, setModalstatus] = useState(false);
  const [formaData, setFormData] = useState({});
  const [formExceptionData, setFormExceptionData] = useState({});
  const [formValidationError, setFormValidationError] = useState({
    errMsg: "",
    status: false,
  });
  const [IsProfilePhotoModalOpen, setProfilePhotoModal] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showCancelledPopup, setShowCancelledPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [deletionErrorMessage, setDeletionErrorMessage] = useState("");
  const onCloseCancelledPopup = (isDeleted) => {
    // refetch();
    setOtp("");
    setPassword("");
    setShowCancelledPopup(false);
    setShowErrorPopup(false);
    if (isDeleted) {
      localStorage.removeItem("accessToken"); // delete token from storage
      localStorage.removeItem("userInfo");
      localStorage.removeItem("email");
      navigate("/");
    }
  };
  const onConfirmDelete = async () => {
    deleteUser({ otp: otp, password: password }).then((resp) => {
      console.log(resp);
      if (resp.data.response.statusEnum !== 1) {
        setDeletionErrorMessage(resp.data.response.message);
        setShowConfirmPopup(false);
        setShowErrorPopup(true);
      } else {
        setShowConfirmPopup(false);
        setShowCancelledPopup(true);
      }
      setOtp("");
      setPassword("");
    });
    // const cancelSubscriptionDetails = await cancelSubscription({
    //   channelId: activeChannelId,
    // });
  };
  const [userGuideLines, setuserGuideLines] = useState(false);

  const toggleUserGuidelines = (e) => {
    setuserGuideLines(!userGuideLines);
  };

  const [Gender, setGender] = useState(false);
  const toggleGender = (e) => {
    setGender(!Gender);
  };

  const [selectedGender, setselectedGender] = useState("-Select-");
  const [genderError, setgenderError] = useState(false);
  const handleGender = (e) => {
    // console.log("🚀 ~ file: Index.jsx:193 ~ handleGender ~ e:", e);
    setGender(!Gender);
    setgenderError(false);
    setselectedGender(e);
    setFormData({
      ...formaData,
      gender: e,
    });
  };

  const [Calender, setCalender] = useState(false);
  const [selectedDate, setselectedDate] = useState("MM/DD/YYYY");
  const [selectedDay, setSelectedDay] = useState("01");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [selectedYear, setSelectedYear] = useState("2000");
  const [currentCalendarView, setCurrentCalendarView] = useState("year");
  const [DOBError, setDOBError] = useState("");
  const [focus, setFocus] = useState({
    username: false,
    dob: false,
    gender: false,
  });

  const [UserError, setUserError] = useState({
    firstName: "",
    lastName: "",
    userName: "",
  });
  const [updateAccountPrivate] = useUpdateAccountPrivateMutation();
  const [checked, setChecked] = useState(false);

  // const [isAvatarChange, setAvatarChange] = useState(true);

  const handleProfilePhotoModal = () => {
    setProfilePhotoModal(!IsProfilePhotoModalOpen);
  };

  const IsFormEditable = () => {
    setFormEditable(!isformEditable);
    focusRef.current?.focus();
    // Save the details of already sign in user data from an API by default
    setFormData(() => {
      // return {
      //   firstname: data.response.data.firstName,
      //   lastname:
      //     data.response.data.lastName === null
      //       ? ""
      //       : data.response.data.lastName,
      // };
      return formaData;
    });
  };

  const handleChangeUserData = (e) => {
    setFormData(() => {
      return {
        ...formaData,
        dob: selectedDate,
        [e.target.name]: e.target.value,
      };
    });
    setFormValidationError(() => {
      return {
        status: false,
        errMsg: "",
      };
    });
  };

  const handleModal = () => {
    setModalstatus(!isModalStatus);
  };

  // const handleSave = (e) => {
  //   setLoad(true);
  //   if (
  //     !IsStringValid(formaData?.firstname || "") ||
  //     !IsStringValid(formaData?.lastname || "") ||
  //     !IsStringValid(formaData?.username || "")
  //   ) {
  //     setFormValidationError(() => {
  //       return {
  //         errMsg: "Something went wrong with input data! Must be an alphabets",
  //         status: true,
  //       };
  //     });
  //     setFormData(() => {
  //       return {
  //         firstname: data.response.data.firstName,
  //         lastname: data.response.data.lastName,
  //         username: data.response.data.userName,
  //         dob: dateConversionTo(data.response.data.dob, "DOB"),
  //         gender: data.response.data.gender,
  //       };
  //     });
  //     setLoad(false);
  //   } else {
  //     updateUserInfo({
  //       firstName: formaData?.firstname,
  //       lastName: formaData?.lastname === "" ? null : formaData?.lastname,
  //       userName: formaData?.username,
  //       dob: selectedDate,
  //       gender: formaData?.gender,
  //     })
  //       .then((res) => {
  //         console.log(res.data);
  //         setFormEditable(!isformEditable);
  //         if (res.data.response.statusEnum === 2) {
  //         } else {
  //           setFormData(() => {
  //             return {
  //               firstname: res.data.response.data.firstName,
  //               lastname: res.data.response.data.lastName,
  //               username: res.data.response.data.userName,
  //               dob: dateConversionTo(res.data.response.data.dob, "DOB"),
  //               gender: res.data.response.data.gender,
  //             };
  //           });
  //         }

  //         setLoad(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }

  //   // updateUsernameandDobGenderQuery({
  //   //   userName: formExceptionData?.username,
  //   //   gender: formExceptionData?.gender,
  //   //   dob: formExceptionData?.dob
  //   // }).then((res) => {
  //   //   setFormEditable(!isformEditable)
  //   //   setFormExceptionData(() => {
  //   //     return {
  //   //       username: res.data.response.data.userName,
  //   //       gender: res.data.response.data.gender,
  //   //       dob: dateConversionTo(res.data.response.data.dob)
  //   //     }
  //   //   })
  //   //   setLoad(false);
  //   // }).catch((err) => {
  //   //   console.log(err)
  //   // })
  // };

  const handleSave = (e) => {
    console.log(formaData);
    setFormValidationError(() => {
      return {
        errMsg: "",
        status: false,
      };
    });
    setLoad(true);
    if (!IsStringValid(formaData?.firstname || "")) {
      setFormValidationError(() => {
        return {
          errMsg: "First Name can only contain alphabets a-z",
          status: true,
        };
      });
      setFormData(() => {
        return {
          firstname: data.response.data.firstName,
          lastname: data.response.data.lastName,
          username: data.response.data.userName,
          dob: dateConversionTo(data.response.data.dob, "DOB"),
          gender: data.response.data.gender,
        };
      });
      setLoad(false);
    } else if (formaData?.lastname && !IsStringValid(formaData?.lastname)) {
      setFormValidationError(() => {
        return {
          errMsg: "Last Name can only contain alphabets a-zs",
          status: true,
        };
      });
      setFormData(() => {
        return {
          firstname: data.response.data.firstName,
          lastname: data.response.data.lastName,
          username: data.response.data.userName,
          dob: dateConversionTo(data.response.data.dob, "DOB"),
          gender: data.response.data.gender,
        };
      });
      setLoad(false);
    } else if (!formaData.username) {
      setFormValidationError(() => {
        return {
          errMsg: "Username must not be empty",
          status: true,
        };
      });
      setFormData(() => {
        return {
          firstname: data.response.data.firstName,
          lastname: data.response.data.lastName,
          username: data.response.data.userName,
          dob: dateConversionTo(data.response.data.dob, "DOB"),
          gender: data.response.data.gender,
        };
      });
      setLoad(false);
    } else if (!isValidUsername(formaData.username)) {
      setFormValidationError(() => {
        return {
          errMsg: "Username can only contain letters and numbers",
          status: true,
        };
      });
      setFormData(() => {
        return {
          firstname: data.response.data.firstName,
          lastname: data.response.data.lastName,
          username: data.response.data.userName,
          dob: dateConversionTo(data.response.data.dob, "DOB"),
          gender: data.response.data.gender,
        };
      });
      setLoad(false);
    } else {
      updateUserInfo({
        firstName: formaData?.firstname,
        lastName: formaData?.lastname === "" ? null : formaData?.lastname,
        userName: formaData?.username,
        dob: selectedDate,
        gender: formaData?.gender || "Male",
      })
        .then((res) => {
          console.log(res.data);
          setFormEditable(!isformEditable);
          if (res.data.response.statusEnum === 2) {
          } else {
            setFormData(() => {
              return {
                firstname: res.data.response.data.firstName,
                lastname: res.data.response.data.lastName,
                username: res.data.response.data.userName,
                dob: dateConversionTo(res.data.response.data.dob, "DOB"),
                gender: res.data.response.data.gender,
              };
            });
          }

          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // updateUsernameandDobGenderQuery({
    //   userName: formExceptionData?.username,
    //   gender: formExceptionData?.gender,
    //   dob: formExceptionData?.dob
    // }).then((res) => {
    //   setFormEditable(!isformEditable)
    //   setFormExceptionData(() => {
    //     return {
    //       username: res.data.response.data.userName,
    //       gender: res.data.response.data.gender,
    //       dob: dateConversionTo(res.data.response.data.dob)
    //     }
    //   })
    //   setLoad(false);
    // }).catch((err) => {
    //   console.log(err)
    // })
  };

  const removeProfilePhoto = () => {
    setLoad(true);
    removeProfilePic({})
      .then((res) => {
        console.log(res.data);
        // setAvatarChange(false);
        setLoad(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isLoading) {
      setFormData(() => {
        return {
          firstname: "Loading info...",
          lastname: "Loading info...",
          username: "Loading info...",
          gender: "Loading info...",
          dob: "Loading info...",
        };
      });
    } else {
      setFormData(() => {
        return {
          ...formaData,
          firstname: data.response.data.firstName,
          lastname:
            data.response.data.lastName === null
              ? ""
              : data.response.data.lastName,
          username: data.response.data.userName,
          gender: data.response.data.gender,
          dob: dateConversionTo(data.response.data.dob, "DOB"),
        };
      });
      const [month, day, year] = dateConversionTo(
        data.response.data.dob,
        "DOB"
      ).split("/");
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      setselectedDate(dateConversionTo(data.response.data.dob, "DOB"));
      setChecked(data.response.data.isPrivate);
    }
  }, [data]);

  const toggleCalender = (e) => {
    setCalender(!Calender);
  };

  // const handleDate = (value) => {
  //   setCalender(false);
  //   const dateString = value.format();
  //   var ToDate = new Date();
  //   if (new Date(dateString).getTime() > ToDate.getTime()) {
  //     setDOBError("Date of Birth must be less than today date");
  //     return;
  //   }
  //   // Split into date and time
  //   const [datePart, timePart] = dateString.split("T");
  //   // Split the date into day, month, and year
  //   const [year, month, day] = datePart.split("-");
  //   // Create the desired format (dd/mm/yyyy)
  //   const formattedDate = `${month}/${day}/${year}`;
  //   setDOBError(null);
  //   setselectedDate(formattedDate);
  //   // console.log(formattedDate); // Output: 11/11/2023
  //   // setFormData({
  //   //   ...formData,
  //   //   dob: formattedDate,
  //   // })
  // };

  const handleDate = (value) => {
    setCurrentCalendarView("year");
    setCalender(false);
    let dateString = value.format();
    const chosenDate = dateString.split("T")[0].split("-")[2];
    setSelectedDay(chosenDate);
    var ToDate = new Date();
    if (
      new Date(`${selectedYear}-${selectedMonth}-${chosenDate}`).getTime() >
      ToDate.getTime()
    ) {
      setDOBError("Date of Birth must be less than today date");
      console.log("test");
      return;
    }
    // Split into date and time
    // const [datePart, timePart] = dateString.split("T");
    // // Split the date into day, month, and year
    // const [year, month, day] = datePart.split("-");
    const year = selectedYear;
    const month = selectedMonth;
    const day = chosenDate;
    // Create the desired format (dd/mm/yyyy)
    const formattedDate = `${month}/${day}/${year}`;
    console.log("formatte date", formattedDate);
    setDOBError(null);
    setselectedDate(formattedDate);
  };

  const onCalendarMonthChange = (value) => {
    setCalender(true);
    setCurrentCalendarView("day");
    const dateString = value.format();
    const month = dateString.split("T")[0].split("-")[1];
    const year = dateString.split("T")[0].split("-")[0];

    const ToDate = new Date();
    if (new Date(`${year}-${month}-01`).getTime() > ToDate.getTime()) {
      setDOBError("Date of Birth must be less than today date");
      setSelectedDay("01");
      setSelectedMonth("1");
      setSelectedYear("2000");
      return;
    }

    // need to change both day and year as well, because changing month can change the year, and different months have different number of days
    setDOBError(null);
    setSelectedDay("01");
    setSelectedMonth(month);
    setSelectedYear(year);
    setselectedDate(`${month}/01/${year}`);
  };

  const onCalendarYearChange = (value) => {
    setCalender(true);
    setCurrentCalendarView("month");
    const dateString = value.format();
    const year = dateString.split("T")[0].split("-")[0];

    const ToDate = new Date();
    if (year > ToDate.getFullYear()) {
      setDOBError("Date of Birth must be less than today date");
      setSelectedYear("2000");
      return;
    }

    setDOBError(null);
    setSelectedYear(year);
    setselectedDate(`${selectedMonth}/${selectedDay}/${year}`);
  };

  const handleUpdateProfilePhoto = (filenameId) => {
    UpdateProfilepic(filenameId)
      .then((res) => {
        refetch();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isDateValid = (dateStr) => {
    return !isNaN(new Date(dateStr));
  };

  const [showCalendar, setShowCalendar] = useState(false);

  const calendarContainerRef = useRef(null);

  const handleAccountPrivate = (e) => {
    setChecked(e.target.checked);
    updateAccountPrivate();
    refetch();
  };
  useEffect(() => {
    console.log(data?.response.data?.photo, "photo");
  }, [data?.response.data?.photo]);
  // this will close the calendar if we click outside it
  useEffect(() => {
    const handleClickOutsideCalendar = (e) => {
      if (
        e.target != calendarContainerRef.current &&
        !calendarContainerRef.current?.contains(e.target) &&
        !e.target.closest(".rdrCalendarWrapper")
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("click", handleClickOutsideCalendar);

    return () =>
      document.removeEventListener("click", handleClickOutsideCalendar);
  }, []);

  if (!data)
    return (
      <div className="__network__error__containeer h-[100vh] w-full flex flex-col justify-center items-center">
        <Loader />
        <span className="pt-4">Loading user profile...</span>
      </div>
    );

  return (
    <div className="__my_profile_main container mx-auto px-[300px] py-[55px] max-[768px]:px-[15px] max-[1280px]:px-0 w-[80%]">
      <div className="__my_avatar_main">
        <h5 className="text-[24px] max-[768px]:text-[18px]">User Profile</h5>
        <div className="__avatar_container py-[30px]">
          <div className="__avatar bg-[#4B4B4B] w-[100px] h-[100px] rounded-[50%] max-[768px]:w-[80px] max-[768px]:h-[80px]">
            {isLoading ? (
              <div className="spinner">
                <img src={spinner} />
              </div>
            ) : data?.response.data?.photo === null ? (
              <img
                className="rounded-[50%] object-cover"
                src={_userDefaultProfileImg}
                alt="__user_default_logo"
              />
            ) : (
              <img
                className="rounded-[50%] object-cover"
                src={data?.response.data?.photo}
                alt="user_avatar_logo"
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if fallback image also fails
                  e.target.src = _userDefaultProfileImg; // Set the path to your fallback image here
                }}
              />
            )}
          </div>
          <div
            className={`__edit_avatar py-[15px] text-[12px] max-[768px]:text-[10px] flex gap-[15px] [&>span]:hover:cursor-pointer`}
          >
            {data?.response.data?.photo === null ? (
              <div className="w-[100px] text-center max-[768px]:w-[80px]">
                <span
                  className="text-[#FBBC5E] text-[14px] max-[768px]:text-[12px] hover:cursor-pointer"
                  onClick={() => handleProfilePhotoModal()}
                >
                  Upload
                </span>
              </div>
            ) : (
              <>
                <span
                  onClick={handleProfilePhotoModal}
                  className="text-[#FBBC5E]"
                >
                  Replace
                </span>
                <span className="text-[#7A7A7A]" onClick={removeProfilePhoto}>
                  {isLoad ? (
                    "Removing..."
                  ) : (
                    <span className="font-medium">Remove</span>
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="__profile_form_main w-max max-[768px]:w-full">
        <h5 className="font-medium text-[18px] max-[768px]:text-[16px]">
          Details
        </h5>
        <div className="__form_container">
          <form onSubmit={() => {}} className="__myprofile_form">
            <div className="flex justify-between gap-[2px] max-[768px]:flex-col">
              <div className="__firstname_field flex flex-col pt-[20px] w-full">
                <label
                  className="font-light text-[14px] max-[768px]:text-[12px]"
                  htmlFor="firstname"
                >
                  First Name
                </label>
                <input
                  ref={focusRef}
                  className={`bg-transparent  mt-[5px] max-[768px]:w-[100%] max-[768px]:ml-0 ml-[3px] rounded-[8px] border border-[#FFFFFF1A] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none`}
                  type="text"
                  name="firstname"
                  placeholder=""
                  onChange={handleChangeUserData}
                  value={formaData.firstname}
                  required
                  disabled={!isformEditable}
                />
              </div>

              <div className="__lastname_field flex flex-col pt-[20px] w-full">
                <label
                  className="font-light text-[14px] max-[768px]:text-[12px]"
                  htmlFor="lastname"
                >
                  Last Name
                </label>
                <input
                  className={`bg-transparent  max-[768px]:w-[100%] max-[768px]:ml-0 mt-[5px] ml-[3px] rounded-[8px] border border-[#FFFFFF1A] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none`}
                  type="text"
                  name="lastname"
                  placeholder=""
                  onChange={handleChangeUserData}
                  value={formaData.lastname}
                  required
                  disabled={!isformEditable}
                />
              </div>
            </div>

            <div className="__username_field relative flex flex-col pt-[20px] ">
              <label
                className="font-light text-[14px] max-[768px]:text-[12px]"
                htmlFor="username"
              >
                Username
              </label>
              <input
                className={`bg-transparent  w-[500px] max-[768px]:w-[100%] max-[768px]:ml-0 mt-[5px] ml-[3px] rounded-[8px] border border-[#FFFFFF1A] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none`}
                type="text"
                name="username"
                placeholder=""
                onChange={handleChangeUserData}
                value={formaData?.username}
                required
                disabled={!isformEditable}
              />
              <span
                className={`vector  absolute left-[93%] max-[668px]:left-[90%] max-[768px]:left-[95%] max-[1280px]:left-[93%] top-12 translate-y-1/2 cursor-pointer text-white`}
                onClick={(e) => toggleUserGuidelines(e)}
                onMouseEnter={(e) => toggleUserGuidelines(e)}
                onMouseLeave={(e) => toggleUserGuidelines(e)}
              >
                <svg
                  className="peer"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="20.5"
                    height="20.5"
                    rx="10.25"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M10.4995 18V9.456H12.4995V18H10.4995ZM11.5075 8.048C11.1342 8.048 10.8248 7.93067 10.5795 7.696C10.3448 7.46133 10.2275 7.17867 10.2275 6.848C10.2275 6.50667 10.3448 6.224 10.5795 6C10.8248 5.76533 11.1342 5.648 11.5075 5.648C11.8808 5.648 12.1848 5.76 12.4195 5.984C12.6648 6.19733 12.7875 6.46933 12.7875 6.8C12.7875 7.152 12.6702 7.45067 12.4355 7.696C12.2008 7.93067 11.8915 8.048 11.5075 8.048Z"
                    fill="white"
                  />
                </svg>
                {
                  <>
                    <div
                      className={`notification-dropdown popover transition-opacity pointer-events-none opacity-0 peer-hover:opacity-100 absolute xl:top-[8px] xl:-right-[32px] top-[40px] right-[0px] rotate-90 xl:rotate-0`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                      >
                        <path
                          d="M19.6752 0.847325L0.643729 11.8411L19.6803 22.826L19.6752 0.847325Z"
                          fill="#716046"
                        />
                      </svg>
                    </div>
                    <div
                      data-testid="Username"
                      className={`absolute transition-opacity pointer-events-none opacity-0 peer-hover:opacity-100 top-[50px] xl:top-0 xl:-right-[380px] w-[500px]  -right-[0px] p-5 max-w-[357px] z-[1] rounded-md bg-[#716046] backdrop-blur-sm`}
                    >
                      <p className="text-[14px] text-[#FBBC5E] font-bold pb-[9px]">
                        Username Guidelines
                      </p>
                      <ul className="pl-5">
                        <li className="text-[14px] font-semibold list-disc">
                          There should be no spaces.
                        </li>
                        <li className="text-[14px] font-semibold list-disc">
                          Username should not start with a number.
                        </li>
                        <li className="text-[14px] font-semibold list-disc">
                          Username should only contain alphabets and numbers.
                        </li>
                        <li className="text-[14px] font-semibold list-disc">
                          Username should not contain any special character.
                        </li>
                      </ul>
                    </div>
                  </>
                }
              </span>

              {/* {userGuideLines && (
                    <div className={`notification-dropdown popover absolute ${screenSize.width > 1226 ? "top-14 left-[53%]" : "top-8 right-4 rotate-180"} delay-200 transition`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                      >
                        <path
                          d="M19.6752 0.847325L0.643729 11.8411L19.6803 22.826L19.6752 0.847325Z"
                          fill="#716046"
                        />
                      </svg>
                    </div>
                  )} */}
            </div>

            <div className={`mb-4 pt-[20px]`}>
              <p
                className="font-light text-[14px] max-[768px]:text-[12px]"
                data-testid="Date Of Birth"
              >
                Date Of Birth
              </p>

              <div
                className="mt-2 flex relative"
                ref={calendarContainerRef}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <div
                  tabIndex={0}
                  className={`rounded-lg w-full  cursor-pointer remove-icon bg-transparent border border-[#FFFFFF1A] focus:border-[#f6f6f6] py-3 px-5 leading-normal font-semibold outline-none ${
                    DOBError ? "!border-error" : ""
                  }`}
                  onClick={(e) => toggleCalender(e)}
                >
                  <div>{selectedDate}</div>
                  <span
                    data-testid="PasswordVisibility"
                    className="vector absolute left-[93%] max-[668px]:left-[90%] max-[768px]:left-[95%] max-[1280px]:left-[93%] top-0 translate-y-1/2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19 4H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V4H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H20V19ZM20 10H4V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H7V7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8C8.26522 8 8.51957 7.89464 8.70711 7.70711C8.89464 7.51957 9 7.26522 9 7V6H15V7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V10Z"
                        fill={`${focus.dob ? "#FBBC5E" : "white"}`}
                      />
                    </svg>
                  </span>
                </div>
                {Calender && (
                  <div className="absolute bg-white text-black top-[51px] z-[4]">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        view={currentCalendarView}
                        disableFuture
                        defaultValue={dayjs(selectedDate)}
                        value={dayjs(
                          `${selectedYear}/${selectedMonth}/${selectedDay}`
                        )}
                        onChange={(newValue) => handleDate(newValue)}
                        onMonthChange={onCalendarMonthChange}
                        onYearChange={onCalendarYearChange}
                      />
                    </LocalizationProvider>
                  </div>
                )} */}

                <div
                  tabIndex={0}
                  className={`rounded-lg w-full  cursor-pointer remove-icon bg-transparent border border-[#FFFFFF1A] focus:border-[#f6f6f6] py-3 px-5 leading-normal font-semibold outline-none ${
                    DOBError ? "!border-error" : ""
                  }`}
                  onClick={(e) => {
                    isformEditable && setShowCalendar(true);
                  }}
                >
                  <div>
                    {isDateValid(selectedDate?.toString()) &&
                      dateFormat(new Date(selectedDate), "mm/dd/yyyy")}
                    {!isDateValid(selectedDate?.toString()) && "MM/DD/YYYY"}
                  </div>
                </div>
              </div>
              {showCalendar && (
                <div className="w-fit">
                  <HyperCalendar
                    minDate={new Date("01/01/1900")}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setselectedDate(dateFormat(new Date(date)), "mm/dd/yyyy");
                    }}
                    date={new Date(selectedDate) || null}
                  />
                </div>
              )}
              {DOBError && (
                <span className="text-error text-sm">{DOBError}</span>
              )}
            </div>

            <div className="__gender_field flex flex-col pt-[20px] relative">
              <label
                className="font-light text-[14px] max-[768px]:text-[12px]"
                htmlFor="gender"
              >
                Gender
              </label>
              <select
                required
                name="gender"
                onChange={handleChangeUserData}
                disabled={!isformEditable}
                className="bg-transparent w-[500px] max-[768px]:w-[100%] max-[768px]:ml-0 mt-[5px] ml-[3px] rounded-[8px] border border-[#FFFFFF1A] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none"
              >
                <option
                  selected={formaData?.gender === "Male" ? "selected" : ""}
                  className="text-[#000] font-medium p-[10px]"
                  value="Male"
                >
                  Male
                </option>
                <option
                  selected={formaData?.gender === "Female" ? "selected" : ""}
                  className="text-[#000] font-medium"
                  value="Female"
                >
                  Female
                </option>

                <option
                  selected={formaData?.gender === "Others" ? "selected" : ""}
                  className="text-[#000] font-medium"
                  value="Others"
                >
                  Other
                </option>
              </select>
            </div>
          </form>
          {formValidationError?.status ? (
            <span className="__error__validation text-[12px] px-2 pt-[10px] text-[#F3A151]">
              {formValidationError?.errMsg}
            </span>
          ) : (
            ""
          )}

          {<p>{UserError.firstName}</p> || <p>{UserError.lastName}</p> || (
            <p>{UserError.userName}</p>
          )}

          <div className="__edit_form_button pt-[60px] ml-[3px]">
            {isformEditable ? (
              <div className="__editable__button_group flex gap-[20px]">
                <button
                  onClick={IsFormEditable}
                  className="bg-[#7A7A7A] max-[768px]:text-[12px] text-[#fff] py-[4px] px-[14px] rounded-[6px] text-[14px]"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="bg-[#F3A151] max-[768px]:text-[12px] font-medium text-[#000] py-[4px] px-[22px] rounded-[6px] text-[14px]"
                >
                  {isLoad ? "Updating user info..." : "Save"}
                </button>
              </div>
            ) : (
              <button
                onClick={IsFormEditable}
                className="border-[2px] border-[#FBBC5E] max-[768px]:text-[10px] py-[2px] px-[25px] rounded-[7px] font-medium text-[12px] hover:bg-[#FBBC5E4D] 
                            hover:text-[#fff] 
                            hover:border-[#FBBC5E4D]"
              >
                Edit
              </button>
            )}
            {/* <div className="__editable__button_group flex gap-[20px]">
              <button
                onClick={IsFormEditable}
                className="bg-[#7A7A7A] max-[768px]:text-[12px] text-[#fff] py-[4px] px-[14px] rounded-[6px] text-[14px]"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="bg-[#F3A151] max-[768px]:text-[12px] font-medium text-[#000] py-[4px] px-[22px] rounded-[6px] text-[14px]"
              >
                {isLoad ? "Updating user info..." : "Save"}
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="__profile__setting_main pt-[70px]">
        <h5 className="font-medium text-[18px]">Account Setting</h5>
        <div className="__setting_data">
          <div className="__email_data pt-[25px]">
            <h5 className="text-[14px] max-[768px]:text-[12px] font-light">
              Email
            </h5>
            <p className="text-[16px] max-[768px]:text-[14px] max-[768px]:mt-[10px] max-[1280px]:mt-[10px]">
              {isLoading
                ? "Loading Info..."
                : data.response.data.email === null
                ? "NA"
                : data.response.data.email}
            </p>
          </div>
          <div className="__pass_data mt-[50px] max-[768px]:mt-[30px]">
            <h5>Password</h5>
            <div className="flex gap-[15px] mt-[20px] max-[768px]:mt-[15px]">
              <p className="text-[14px] h-[30px] leading-[30px] mt-[2px] tracking-[3px] max-[768px]:text-[12px] max-[768px]:tracking-[2px]">
                ***********
              </p>
              <button
                onClick={() => {
                  setModalstatus(!isModalStatus);
                }}
                className="text-[16px] text-[#FBBC5E] hover:underline max-[768px]:text-[14px]"
              >
                Change
              </button>
              <p className="text-[16px] text-[#FFFFFF80] mt-[4px] max-[768px]:text-[13px] max-[768px]:mt-[9px]">
                {data.response.data.lastPasswordChange === 0
                  ? ""
                  : "( last changed " +
                    dateConversionTo(data.response.data.lastPasswordChange) +
                    " )"}
              </p>
            </div>
          </div>
          <div className="mt-[50px] max-[768px]:mt-[30px] flex flex-col gap-y-[12px] ">
            <div className="flex items-center gap-x-2 ">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 9H12.4C12.5591 9 12.7117 9.06321 12.8243 9.17574C12.9368 9.28826 13 9.44087 13 9.6V16.4C13 16.5591 12.9368 16.7117 12.8243 16.8243C12.7117 16.9368 12.5591 17 12.4 17H1.6C1.44087 17 1.28826 16.9368 1.17574 16.8243C1.06321 16.7117 1 16.5591 1 16.4V9.6C1 9.44087 1.06321 9.28826 1.17574 9.17574C1.28826 9.06321 1.44087 9 1.6 9H3M11 9V5C11 3.667 10.2 1 7 1C3.8 1 3 3.667 3 5V9M11 9H3"
                  stroke="#CCCCCC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="text-[14px] font-medium text-white/80 ">
                Account Privacy
              </span>
            </div>
            <label class="inline-flex items-center gap-x-4 cursor-pointer ">
              <span class="font-medium ">Private Account</span>
              <input
                type="checkbox"
                className="sr-only peer"
                checked={checked}
                onChange={handleAccountPrivate}
              />
              <div class="relative w-11 h-6 bg-white/20 rounded-full peer  dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white/20 after:border-gray-300 peer-checked:after:bg-white peer-checked:after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#FBBC5E] "></div>
            </label>
            <p className="text-[12px] text-white/50 font-medium ">
              Making your account private will hide your profile from search
              results, and others will be unable to send you friend requests.
            </p>
          </div>
        </div>

        <div className="__user__preference__tags__section flex gap-[21px] pt-[45px]">
          <h4 className="text-[18px] font-medium font-body">
            Preferences and tags
          </h4>
          <a href="/me/edit/preference">
            <button className="text-[16px] text-[#FBBC5E] hover:underline max-[768px]:text-[14px]">
              Edit
            </button>
          </a>
        </div>
        <div className="__user__preference__tags__section flex gap-[21px] pt-[45px]">
          <button
            className="text-[18px] font-bold text-[#fb5e5e] max-[768px]:text-[14px]"
            onClick={() => {
              sendDeleteUserOtp();
              setShowConfirmPopup(true);
            }}
          >
            Delete Account
          </button>
        </div>
      </div>
      {showCancelledPopup && (
        <Deleted
          open={showCancelledPopup}
          canClose={true}
          onClose={() => onCloseCancelledPopup(true)}
        />
      )}
      {showErrorPopup && (
        <DeletionError
          open={showErrorPopup}
          canClose={true}
          onClose={() => onCloseCancelledPopup(false)}
          message={deletionErrorMessage}
        />
      )}
      {showConfirmPopup && (
        <ConfirmDeletion
          open={() => showConfirmPopup}
          onClose={() => {
            setShowConfirmPopup(false);
            setOtp("");
            setPassword("");
          }}
          deleteBtnHandler={onConfirmDelete}
          resendOtpHandler={() => reSendDeleteUserOtp()}
          resendOtpHandlerInfo={reSendDeleteUserOtpInfo}
          isLoading={deleteUserInfo.isLoading}
          otp={otp}
          setOtp={setOtp}
          password={password}
          setPassword={setPassword}
        />
      )}
      {isModalStatus ? (
        <PasswordModal
          handleModalStatus={handleModal}
          modalStatus={isModalStatus}
        />
      ) : (
        <></>
      )}

      {IsProfilePhotoModalOpen ? (
        <ProfilePhotoModal
          handlePhotoModal={handleProfilePhotoModal}
          UpdateProfilePic={handleUpdateProfilePhoto}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyProfile;
