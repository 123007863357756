import axios from "axios";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "constant/api/Endpoints";
import { toast } from "react-hot-toast";

const webRequestHandler = (url) => {
  const isClientSide = typeof window !== "undefined";
  const token = isClientSide ? localStorage.getItem("accessToken") : null;
  const showErrorNotification = (message) => {
    toast.error(message, {
      id: "network_issue",
      duration: 4000,
      style: {
        border: "1px solid #ff4d4d",
        padding: "16px",
        background: "#ff4d4d",
        color: "#FFFAEE",
      },
      iconTheme: {
        secondary: "#ff4d4d",
        primary: "#FFFAEE",
      },
    });
  };

  const instance = axios.create({
    baseURL: url,
    headers: {
      Authorization: isClientSide && token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    // ... other options
  });
  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      if (
        response.data.response.statusEnum >= 400 &&
        response.data.response.statusEnum < 500
      ) {
        localStorage.clear();
        window.location.href = "/signin";
      }
      // Return the response if the status code is 200
      return response.data;
    },
    (error) => {
      // Perform an action if the status code is 401
      if (!error.response) {
        // Handle network error
        axios
          .get("https://1.1.1.1/cdn-cgi/trace")
          .then((pingResponse) => {
            // If the ping succeeds, show an alternate error message
            const errorMessage =
              "Some services are temporarily unavailable. We're addressing the issue and will restore full functionality shortly. Thank you for your understanding.";
            showErrorNotification(errorMessage);
          })
          .catch((e) => {
            // If the ping fails, it's likely a network issue
            const networkError =
              "Network error occurred. Please check your internet.";
            showErrorNotification(networkError);
          });
      } else if (error.response.status === 502) {
        // Handle 500-599 server-side errors
        const networkError =
          "Network error occurred. Please check your internet.";
        showErrorNotification(networkError);
      } else if (error.response.status >= 500 && error.response.status < 600) {
        // Handle 500-599 server-side errors
        const serverError = `Server error (${error.response.status}): Please try again later.`;
        showErrorNotification(serverError);
      } else if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        // Here, you can perform any action you need when the status code is 401
        console.error(
          "Request failed with status code:",
          error.response.status
        );
        localStorage.clear();
        window.location.href = "/signin";

        // For example, you can redirect the user, show an error message, etc.
      }

      // Return the rejected Promise to propagate the error
      return Promise.reject(error);
    }
  );

  return instance;
};

export default webRequestHandler;

// // Custom base query with error handling
// export const customBaseQuery = async (args, api, extraOptions) => {
//   const rawBaseQuery = fetchBaseQuery();
//   const result = await rawBaseQuery(args, api, extraOptions);
//   if (result.error && result.error.originalStatus >= 400 && result.error.originalStatus < 500) {
//     // Handle 400-499 errors
//     console.error("Request failed with status code:", result.error.originalStatus);
//     localStorage.clear();
//     window.location.href = "/signin";
//     // Optionally, you can return a new error or result
//     return {
//       error: { status: result.error.originalStatus, message: "Custom error handling" },
//     };
//   }

//   return result;
// };
// Custom base query function that takes a dynamic config object
const customBaseQuery = ({ baseUrl, prepareHeaders }) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: prepareHeaders || ((headers) => headers),
  });
};

const showErrorNotification = (message) => {
  toast.error(message, {
    id: "network_issue",
    duration: 4000,
    style: {
      border: "1px solid #ff4d4d",
      padding: "16px",
      background: "#ff4d4d",
      color: "#FFFAEE",
    },
    iconTheme: {
      secondary: "#ff4d4d",
      primary: "#FFFAEE",
    },
  });
};

// Base query with error handling
export const baseQueryWithReauth =
  (config) =>
  async (args, api, extraOptions = {}) => {
    const baseQuery = customBaseQuery(config);
    let result = await baseQuery(args, api, extraOptions.showError);
    const showError =
      extraOptions.showError !== undefined ? extraOptions.showError : true;

    if (
      !result ||
      (result.error &&
        (result.error.status === "FETCH_ERROR" ||
          result.error.status === "NETWORK_ERROR"))
    ) {
      axios
        .get("https://1.1.1.1/cdn-cgi/trace")
        .then((pingResponse) => {
          // If the ping succeeds, show an alternate error message
          const errorMessage =
            "Some services are temporarily unavailable. We're addressing the issue and will restore full functionality shortly. Thank you for your understanding.";
          showErrorNotification(errorMessage);
          showError && showErrorNotification(errorMessage);
        })
        .catch((e) => {
          // If the ping fails, it's likely a network issue
          const networkError =
            "Network error occurred. Please check your internet.";
          showErrorNotification(networkError);
          showError && showErrorNotification(networkError);
        });
    } else if (result.data.statusCode >= 500 && result.data.statusCode < 600) {
      // Handle 500-599 server-side errors
      const serverError = `Server error (${result.data.statusCode}): Please try again later.`;
      showErrorNotification(serverError);
    } else if (result.data.statusCode >= 400 && result.data.statusCode < 500) {
      console.error("Request failed with status code:", result.data.statusCode);
      localStorage.clear();
      window.location.href = "/signin";
    }

    return result;
  };
