import React from "react";
import Apartments from "./Apartments";
import Ships from "./Ships";
import Gaming from "./Gaming";
import Clothing from "./Clothing";
import Companion from "./Companion";
import Avatar from "./Avatar";
import { useState, useEffect } from "react";
import webRequestHandler from "constant/api/apiInstance";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import Channels from "./Channels";
import Loader from "components/Loader/Loader";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Assets-Home-Module
 *@developer Aditya P
 */

const myAssetsTabs = [
  {
    id: 0,
    parentId: 0,
    key: "apartments",
    name: "Apartments",
    children: null,
    component: <Apartments />,
  },
  {
    id: 1,
    parentId: 0,
    key: "ships",
    name: "Ships",
    children: null,
    component: <Ships />,
  },
  {
    id: 3,
    parentId: 0,
    key: "clothing",
    name: "Clothing",
    children: [
      {
        id: 0,
        key: "space-suits",
        name: "Space Suits",
      },
      {
        id: 1,
        key: "top-wear",
        name: "Top Wear",
      },
      {
        id: 2,
        key: "bottom-wear",
        name: "Bottom Wear",
      },
      {
        id: 3,
        key: "accessories",
        name: "Accessories",
      },
    ],
    component: <Clothing />,
  },
  {
    id: 4,
    parentId: 0,
    key: "companion",
    name: "Companions",
    children: null,
    component: <Companion />,
  },
  {
    id: 4,
    parentId: 0,
    key: "avatar",
    name: "Avatar",
    children: null,
    component: <Avatar />,
  },
  {
    id: 5,
    parentId: 0,
    key: "game",
    name: "Gaming",
    children: null,
    component: <Gaming />,
  },
];

const MyAssetsHome = () => {
  const [activeTabs, setActivetab] = useState({
    id: 0,
    parentId: 0,
    key: "apartments",
    name: "Apartments",
    children: [],
    component: <Apartments />,
  });
  const [assetData, setAssetData] = useState([]);
  const instance = webRequestHandler();

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    // Simulate fetching data from an API
    try {
      const r = await instance.get(
        `${BASE_URL}${EndpointsSlug.IAP_ASSET_LIST}`
      );
      console.log(r, "line128");
      if (r.response.data) {
        const newData = r.response.data;
        let updatedNewData = [];
        // eslint-disable-next-line array-callback-return
        newData.map((item) => {
          updatedNewData.push({
            slug: item.IAPslug ?? item.iapId,
            img: item.thumbnail?.url ?? item.thumbnail, // If tumbnail is an object type then we will add
            title: item.iapName,
            desc: item.description,
            iapCategory: item.iapCategory,
            iapType: item.iapType,
            iapSuperType: item.iapSuperType,
            iapId: item.iapId,
            iapName: item.iapName,
            iapDetails: item.details,
            assetId:item._id
          });
        });
        setAssetData(updatedNewData);
        updatedNewData = updatedNewData.filter(
          (item) => item.iapCategory === "apartment"
        );
        console.log(updatedNewData, "line146");
        setActivetab({
          ...activeTabs,
          component: <Apartments apartmentData={updatedNewData} />,
        });
      }
    } catch (error) {
      setActivetab({
        ...activeTabs,
        component: <Apartments apartmentData={[]} />,
      });
      console.error("Error while fetching iap asset list", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAsset = (tabs) => {
    let result;
    switch (tabs.key) {
      case "apartments":
        result = assetData.filter((item) => item.iapCategory === "apartment");
        setActivetab({
          ...tabs,
          component: <Apartments apartmentData={result} />,
        });
        break;
      case "ships":
        result = assetData.filter((item) => item.iapCategory === "ship");
        setActivetab({ ...tabs, component: <Ships shipData={result} /> });
        break;
      case "clothing":
        result = assetData.filter((item) => item.iapCategory === "clothing");
        setActivetab({
          ...tabs,
          component: <Clothing dataClothing={result} />,
        });
        break;

      case "companion":
        result = assetData.filter((item) => item.iapCategory === "companion");
        setActivetab({
          ...tabs,
          component: <Companion dataCompanion={result} />,
        });
        break;
      case "avatar":
        result = assetData.filter((item) => item.iapCategory === "avatar");
        setActivetab({ ...tabs, component: <Avatar dataAvatar={result} /> });
        break;
      case "game":
        result = assetData.filter((item) => item.iapCategory === "gaming");
        setActivetab({ ...tabs, component: <Gaming /> });
        break;
      default:
        result = assetData.filter((item) => item.iapCategory === "channel");
        setActivetab({
          ...tabs,
          component: <Channels />,
        });
    }
  };

  if (isLoading) {
    return (
      <div className="h-[500px] w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div
      data-testid="my-assetsHome-main"
      className="__myassets_container_main px-[15px] py-[35px] flex max-[768px]:flex-col max-[768px]:p-0 max-[1280px]:py-[25px]"
    >
      <div
        data-testid="myAssets-category-main"
        className="__assets_category_panel w-[20%] py-[20px] max-[768px]:w-auto max-[768px]:p-0 max-[1280px]:p-0"
      >
        <h5 className="text-[grey] font-body font-bold tracking-[1px] max-[768px]:hidden">
          My Assets
        </h5>
        <ul
          className="overflow-y-scroll
                    relative
                     px-[0px]
                     [&>li]:px-[12px] 
                     [&>li]:py-[10px] 
                     [&>li]:font-light 
                     [&>li]:font-body
                     [&>li]:my-[15px] 
                     hover:[&>li]:bg-[#FBBC5E1A]
                     hover:[&>li]:rounded-[5px]
                     hover:[&>li]:cursor-pointer
                     max-[768px]:overflow-x-scroll
                     max-[768px]:flex
                     max-[768px]:[&>li]:py-[5px]
                     max-[768px]:[&>li]:my-[10px]
                     max-[768px]:px-0
                     max-[768px]:text-[11px]
                     "
        >
          {myAssetsTabs?.map((tabs, index) => {
            return (
              <li
                key={tabs.key}
                className={`hover:bg-[#FBBC5E4D] rounded-[5px] ${
                  activeTabs.key === tabs.key ? "!bg-[#FBBC5E4D]" : ""
                }`}
                onClick={() => handleClickAsset(tabs)}
              >
                {tabs.name}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="__assets_tabs_container_panel w-[80%] p-[10px] max-[768px]:w-auto max-[768px]:h-auto max-[768px]:mt-[18px]">
        {activeTabs.component}
      </div>
    </div>
  );
};

export default MyAssetsHome;
